import {
  doc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useContext, useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { userContext } from "../../context/userContext";
import { auth, db } from "../../firebase/firebase";
import { OutlinedBox } from "../../styles";

// action
// asset
// amount

const Stocks = ({ action, assets, user, open }) => {

  const {fixTrade, setFixTrade} = open;

  const { accounts, currentPrices, currentAccount } = useContext(userContext);
  const [asset, setAsset] = useState("AAPL");
  const [assetPrice, setAssetPrice] = useState(currentPrices[asset]);
  const [stockAccounts, setStockAccounts] = useState([]);
  const [fiatAccount, setFiatAccount] = useState([]);
  const [balance, setBalance] = useState("");
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [id, setID] = useState("");
  const [liveAccount, setLiveAccount] = useState([]);
  const [practiceAccount, setPracticeAccount] = useState([]);
  // const [accounts, setAccounts] = useState([]);
  const [time, setTime]  = useState(2)
  const [tp, setTP] = useState("");
  const [sl, setSL] = useState("");
  const [tpBuyError, setTPBuyError] = useState(false);
  const [tpSellError, setTPSellError] = useState(false);
  const [slBuyError, setSLBuyError] = useState(false);
  const [slSellError, setSLSellError] = useState(false);
  const [isAdvanced, setIsAdvanced] = useState(false)
  const tpRef = useRef();
  const slRef = useRef();
  const [profit, setProfit] = useState("");
  const entryRef = useRef();

  const profitRef = useRef();

  const [entry, setEntry] = useState(assetPrice)

  const [extraOptions, setExtraOptions] = useState(false)

  const [profitType, setProfitType] = useState('profit')

  useEffect(() => {
    if (accounts) {
      const { live, practice } = accounts;
      if (live && practice) {
        setLiveAccount(live);
        setPracticeAccount(practice);
      }
    } else {
      return;
    }
  }, [accounts]);

  useEffect(() => {
    if (currentAccount && liveAccount && practiceAccount) {
      switch (currentAccount) {
        case "live":
          setStockAccounts(liveAccount.Stock);
          setFiatAccount(liveAccount.Fiat);
          break;
        case "practice":
          setStockAccounts(practiceAccount.Stock);
          setFiatAccount(practiceAccount.Fiat);
          break;
        default:
          break;
      }
    }
  }, [currentAccount, liveAccount, practiceAccount]);

  useEffect(() => {
    if (user) {
      setID(user.uid);
    }
  }, [user]);

  function handleAssetChange(e) {
    const value = e.target.value;

    assets.forEach(acc => {
      if(acc.name === value) {
        setAsset(acc.symbol);
      }
    })
  }

  useEffect(() => {
    if (asset) {
      setAssetPrice(currentPrices[asset]);
      setEntry(currentPrices[asset])
    }
  }, [asset]);

  function handleAmount(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount("");
    }
  }


  function handleProfit(e) {
    const value = e.target.value;
    if (value > 0) {
      setProfit(value);
    } else {
      setProfit("");
    }
  }

  // function handleEntry(e) {
  //   const value = e.target.value;
  //   if (value > 0) {
  //     setEntry(value);
  //   } else {
  //     setEntry(assetPrice);
  //   }
  // }

  function handleTP(e) {
    const value = e.target.value;
    if (value > 0) {
      setTP(value);
    } else {
      setTP("");
    }

    if (tp && action === "buy" && value <= assetPrice) {
      setTPBuyError(true);
    } else {
      setTPBuyError(false);
    }

    if (tp && action === "sell" && value >= assetPrice) {
      setTPSellError(true);
    } else {
      setTPSellError(false);
    }
  }

  function handleSL(e) {
    const value = e.target.value;
    if (value > 0) {
      setSL(value);
    } else {
      setSL("");
    }

    if (sl && action === "buy" && value >= assetPrice) {
      setSLBuyError(true);
    } else {
      setSLBuyError(false);
    }

    if (sl && action === "sell" && value <= assetPrice) {
      setSLSellError(true);
    } else {
      setSLSellError(false);
    }
  }

  function handleTimeInForce(e) {
    const value = e.target.value;
    // const timeSlice = value.slice(0, value.indexOf(" "));
    setTime(Number(value));
  }

  function reset() {
    setAmount("");
    setAmountError(false);
    setTPBuyError(false);
    setTPSellError(false);
    setSLBuyError(false);
    setSLSellError(false);

    amountRef.current.value = "";

    // if(entryRef) {
    //   entryRef.current.value = "";
    // }

    if (isAdvanced && slRef) {
      slRef.current.value = "";
    }

    if (isAdvanced && tpRef) {
      tpRef.current.value = "";
    }
  }

  useEffect(() => {
    if (asset && fiatAccount && stockAccounts) {
      if (action === "buy") {
        setBalance(fiatAccount?.value);
      }

      if (action === "sell") {
        setBalance(stockAccounts[asset]?.value);
      }
    }
  }, [fiatAccount, action, asset, balance, amount, stockAccounts]);

  function handleTrade () {
    setIsSubmitting(true)

    submitUp()
  }

  async function submitUp(){
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const converted = Number(
      amount / currentPrices[asset]
    ).toFixed(2);

    const str =
      user.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "open",
      fixed: true,
      result: profitType.charAt(0).toUpperCase() + profitType.substring(1),
      type: "Stocks",
      profit: profit ? profit : 0,
      profitType,
      converted,
      fixType: action,
      duration: time,
      user,
      amount,
      asset,
      price: assetPrice,
      action: action === 'up' ? 'Buy' : "Sell",
      details: [
        {
          name: "Action",
          value: action === "up" ? 'Buy' : "Sell",
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    })
      .then(() => {
        setIsSubmitting(false);
        setFixTrade(false)
        const details = {
          fixed: true,
          profit: profit ? profit : 0,
          profitType,
          action: action === "up" ? 'Buy' : "Sell",
          account: "live",
          entry: assetPrice,
          asset,
          time,
          amount,
          symbol: asset
      }
      postTrade(user, str, details);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("Trade Could Not Complete.");
        setIsSubmitting(false);
      });
  }

  async function postTrade(user, str, details) {
    const url = "https://fin-http-production.up.railway.app/globalfinex/stocks";

    const base = {
      user,
      ref: str,
      details,
    };

    const config = {
      method: "POST",
      // mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(base),
    };

    await fetch(url, config)
      .then((response) => {
        if (response) {
          toast.success("Trade sucessfully placed.");
          setIsSubmitting(false);
          setFixTrade(false)
          // reset();
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("Trade Could Not Complete.");
        setIsSubmitting(false);
      });
  }



  const amountRef = useRef();

  
  function handleTypeChange (e) {
    const {value} = e.target
    setProfit(true)
    setProfitType(value)
  }



  return (
    <Wrapper>
      <div className="selectWrap">
        <label htmlFor="pair">Asset</label>
        <div className="selectBox">
          <div className="selectTop">
            {/* <span> */}
            <select name="asset" onChange={handleAssetChange}>
              {assets.map((ass) => (
                <option key={ass.symbol}>{ass.name}</option>
              ))}
            </select>
            <img src="/extraicons/arrowdown.svg" alt="select" />
            {/* </span> */}
          </div>

          <div className="captions">
            <span></span>

            {assetPrice && (
              <p className="balance">
                Current Price{" "}
                <span className="green">~${Number(assetPrice).toFixed(2)}</span>
              </p>
            )}
          </div>
        </div>
      </div>

  

      <div className="selectWrap">
        <label htmlFor="pair">Type</label>
        <div className="selectBox">
          <div className="selectTop">
            <select name="asset" onChange={handleTypeChange}>
             <option value="profits">Profit</option>
             <option value="loss">Loss</option>
            </select>
            <img src="/extraicons/arrowdown.svg" alt="select" />
          </div>
        </div>
      </div>
      
      <div className="amWrap">
        <label htmlFor="amount">Amount</label>
        <div className="amBx">
          <div className="amTop">
            <input
              placeholder="1000"
              name="amount"
              type="number"
              ref={amountRef}
              style={{ padding: "1rem" }}
              onChange={handleAmount}
            />
          </div>
        </div>
      </div>

      {/* <div
        className="clickable"
        style={{
          display: "flex",
          placeSelf: "end",
          alignItems: "center",
          gap: "0.3rem",
        }}
      >
        <input
          type="checkbox"
          style={{ accentColor: "#1199fa" }}
          onChange={() => setExtraOptions(!extraOptions)}
        />
        Extra
      </div> */}

     

      {/* <div className="amWrap">
        <label htmlFor="amount">Entry Price</label>
        <div className="amBx">
          <div className="amTop">
            <input
              placeholder={Number(assetPrice).toFixed(2)}
              defaultValue={Number(assetPrice).toFixed(2)}
              name="amount"
              type="number"
              style={{ padding: "1rem" }}
              onChange={handleEntry}
              ref={entryRef}
            />
          </div>
        </div>
      </div> */}

      {/* {extraOptions &&  <div className="amWrap">
        <label htmlFor="amount">Profit</label>
        <div className="amBx">
          <div className="amTop">
            <input
              placeholder="1000"
              name="amount"
              type="number"
              style={{ padding: "1rem" }}
              onChange={handleProfit}
              ref={profitRef}
            />
          </div>
          
        </div>
      </div> } */}


      <div className="selectWrap">
        <label htmlFor="timeinforce">Duration(minutes)</label>
        <div className="selectBox">
          <div className="selectTop">
          <select name="timeinforce" onChange={handleTimeInForce}>
              <option>2</option>
              <option>5</option>
              <option>10</option>
              <option>15</option>
              <option>30</option>
            </select>
            <img src="/extraicons/arrowdown.svg" alt="select" />
          </div>
        </div>
      </div>
    
      <button
        disabled={!amount || !asset || !assetPrice || !time}
        className={
          !amount || !asset || !assetPrice || !time 
            ? "button disabled"
            : "button submit"
        }
        onClick={handleTrade}
      >
        {isSubmitting ? (
          <img
            src="/svg-loaders/tail-spin.svg"
            alt="loading"
            width={24}
            height={24}
          />
        ) : (
          <span className="capitalize" style={{alignSelf: "center"}}>
           {"Execute Trade"}
          </span>
        )}
      </button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  gap: 0.8rem;

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }
`;

export default Stocks;
