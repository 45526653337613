import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAK0XTQlA4KNkBsj0Nlvh8amm_7B6wnBac",
  authDomain: "global-finex.firebaseapp.com",
  projectId: "global-finex",
  storageBucket: "global-finex.appspot.com",
  messagingSenderId: "938489896633",
  appId: "1:938489896633:web:f347e443dcd176668d0568",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBGq25Hh0nt1zy-G4L32-hYypt6LDS9ULc",
//   authDomain: "finance-test-5f328.firebaseapp.com",
//   projectId: "finance-test-5f328",
//   storageBucket: "finance-test-5f328.appspot.com",
//   messagingSenderId: "261981000578",
//   appId: "1:261981000578:web:ee913ada0c961a2803759b",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
